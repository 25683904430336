export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":["en-GB","en-US"]},
  vueI18nLoader: false,
  locales: [{"code":"en-GB","iso":"en-GB","file":"en-GB.ts","name":"English (UK)","name_native":"English (UK)"}],
  defaultLocale: "en-GB",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "/codebuild/output/src2673517516/src/cavu-white-label/applications/white-label-account/src/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":"parking.coffsharbourairport.com.au","cookieKey":"i18n_language","cookieSecure":false,"fallbackLocale":"en-GB","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en-GB","iso":"en-GB","file":"en-GB.ts","name":"English (UK)","name_native":"English (UK)"}],
  localeCodes: ["en-GB"],
  additionalMessages: [],
}

export const localeMessages = {
  'en-GB.ts': () => import('../../src/lang/en-GB.ts' /* webpackChunkName: "lang-en-GB.ts" */),
}
